import { func } from "prop-types";

/**
 * Created by pierremarsot on 24/07/2017.
 */
export const SEND_MESSAGE_TOAST = 'SEND_MESSAGE_TOAST';
export const CLEAR_MESSAGE_TOAST = 'CLEAR_MESSAGE_TOAST';

let listenersToast = [];

export function listenToastMessage(callback) {
  listenersToast.push(callback);
}

export function removeListener(callback) {
  listenersToast = listenersToast.filter(c => c !== callback);
}

export function sendMessageSuccess(message){
  for(const handler of listenersToast){
    handler(message, 1);
  }
}

export function sendMessageError(message){
  for(const handler of listenersToast){
    handler(message, 2);
  }
}