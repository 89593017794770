/**
 * Created by pierremarsot on 24/07/2017.
 */
import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {listenToastMessage, removeListener} from '../../actions/toast';

export default class Toast extends React.Component {
  state = {
    open: false,
    message: "",
    type: -1
  };

  componentDidMount(){
    listenToastMessage(this.receiveMessage);
  }

  componentWillUnmount(){
    removeListener(this.receiveMessage);
  }

  receiveMessage = (message, type) => {
    this.setState({
      message,
      type,
      open: true,
    });
  };

  handleRequestClose = () => {
    this.setState({open: false, message: null, type: -1});
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.handleRequestClose}
        message={<span id="message-id">{this.state.message}</span>}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={this.handleRequestClose}>
              Fermer
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleRequestClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    )
  }
}