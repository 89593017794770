/**
 * Created by pierremarsot on 24/07/2017.
 */
import { postApi, get } from '../tools/api';
import { setLocalStorage, deleteLocalStorage, ID_TOKEN, getToken } from '../tools/localStorage';
import { getSettings } from './settings';

const validator = require("email-validator");
let connectedUser = null;

export function login(email, password) {
  return new Promise((resolve, reject) => {
    if (!email || email.length === 0) {
      return reject("Vous devez spécifier un email");
    }

    if (!validator.validate(email)) {
      return reject("Votre email n'est pas valide");
    }

    if (!password || password.length === 0) {
      return reject("Vous devez spécifier un mot de passe");
    }

    postApi("/login/backend", {
      email: email,
      password: password
    })
      .then((response) => {
        if (response && response.token) {
          setLocalStorage(ID_TOKEN, response.token);

          Promise.all([getSettings(), getConnectedUser()])
            .then(() => resolve())
            .catch(err => reject(err));
        }
        else {
          return reject("Erreur inconnue lors de la connexion");
        }
      })
      .catch((response) => {
        const error = response && response.error ? response.error : "Erreur inconnue lors de la connexion";
        return reject(error);
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    deleteLocalStorage(ID_TOKEN);
    return resolve();
  });
}

export function getConnectedUser() {
  return new Promise((resolve, reject) => {
    if (connectedUser) {
      resolve(connectedUser);
      return;
    }

    get("/api/users")
      .then(profil => {
        connectedUser = profil.profil;
        resolve(connectedUser)
      })
      .catch(err => reject(err));
  });
}