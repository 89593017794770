/**
 * Created by pierremarsot on 15/05/2017.
 */
import superagent from 'superagent';
import superagentJsonapify from 'superagent-jsonapify';
import {getToken} from './localStorage';

superagentJsonapify(superagent);

export const url = process.env.NODE_ENV === "development" ?
  "https://api-backend71.agricivis.ovh" :
  "https://api-backend71.agricivis.ovh";

export function downloadFileAsText(path) {
  return new Promise((resolve, reject) => {
    const callback = (error, response) => {
      if (!response) {
        return reject({
          error: 'Erreur de connexion avec le serveur'
        }, 404);
      }
      if (response.ok) {
        return resolve(response.text, response.type, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();

    if (token && token.length > 0) {
      superagent.get(url + path)
        .query({token: token})
        .set("Authorization", "Bearer " + token)
        .end(callback);
    }
    else {
      superagent.get(url + path)
        .end(callback);
    }
  });
}

export function get(path, query) {
  return new Promise((resolve, reject) => {
    const callback = (error, response) => {
      if (!response) {
        return reject({
          error: 'Erreur de connexion avec le serveur'
        }, 404);
      }
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();

    if (token && token.length > 0) {
      superagent.get(url + path)
        .query(query)
        .set("Authorization", "Bearer " + token)
        .end(callback);
    }
    else {
      superagent.get(url + path)
        .query(query)
        .end(callback);
    }
  });
}

export function postApi(path, data = {}) {
  console.log("postApi");
  return new Promise((resolve, reject) => {

    const callback = (error, response) => {
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();

    if (token && token.length > 0) {
      superagent.post(url + path)
        .send(data)
        .set("Authorization", "Bearer " + token)
        .end(callback);
    }
    else {
      superagent.post(url + path)
        .send(data)
        .end(callback);
    }
  });
}

export function postMultipartApi(path, data = [], file) {
  return new Promise((resolve, reject) => {
    const callback = (error, response) => {
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();
    if (!token) {
      return reject({
        error: 'Erreur lors de la récupération de votre identifiant'
      });
    }

    let req = superagent.post(url + path);
    req = req.attach("file", file);

    if(data && data.length > 0){
      for (const d of data) {
        req = req.field(d.key, d.value);
      }
    }

    req = req.set('Authorization', "Bearer " + token);
    req.end(callback);
  });
}

export function putMultipartApi(path, data = [], file) {
  return new Promise((resolve, reject) => {
    const callback = (error, response) => {
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();
    if (!token) {
      return reject({
        error: 'Erreur lors de la récupération de votre identifiant'
      });
    }

    let req = superagent.put(url + path);
    req = req.attach("file", file);

    if(data && data.length > 0){
      for (const d of data) {
        req = req.field(d.key, d.value);
      }
    }

    req = req.set('Authorization', "Bearer " + token);
    req.end(callback);
  });
}


export function putApi(path, data = {}) {
  return new Promise((resolve, reject) => {

    const callback = (error, response) => {
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();

    if (token && token.length > 0) {
      superagent.put(url + path)
        .send(data)
        .set("Authorization", "Bearer " + token)
        .end(callback);
    }
    else {
      superagent.put(url + path)
        .send(data)
        .end(callback);
    }
  });
}

export function removeApi(path, query = {}) {
  return new Promise((resolve, reject) => {


    const callback = (error, response) => {
      if (response.ok) {
        return resolve(response.body, response.statusCode);
      }

      return reject(response.body, response.statusCode);
    };

    const token = getToken();

    if (token && token.length > 0) {
      superagent.delete(url + path)
        .query(query)
        .set("Authorization", "Bearer " + token)
        .end(callback);
    }
    else {
      superagent.delete(url + path)
        .query(query)
        .end(callback);
    }
  });
}