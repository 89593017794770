import React from 'react';
import Button from '@material-ui/core/Button';
import { loadTypesRisque, removeTypeRisque } from '../../actions/typesRisque';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import { TableContainer } from '@material-ui/core';

export default class TypeRisques extends React.Component {
  state = {
    typesRisque: [],
  };

  componentDidMount() {
    this.__loadTypesRisque();
  }

  __loadTypesRisque = () => {
    const that = this;
    loadTypesRisque()
      .then((typesRisque) => {
        that.setState({
          typesRisque,
        });
      });
  };

  handleRemoveTypeRisque = (idTypeRisque) => {
    removeTypeRisque(idTypeRisque)
      .then(() => {
        this.__loadTypesRisque();
        sendMessageSuccess("Type de risque bien supprimé")
      })
      .catch(error => sendMessageError(error));
  };

  render() {
    const { typesRisque } = this.state;
    let data = [];

    if (typesRisque?.length > 0) {
      for (const typeRisque of typesRisque) {
        data.push(
          <TableRow key={typeRisque._id}>
            <TableCell>{typeRisque.nom}</TableCell>
            <TableCell>
              <Button onClick={() => this.props.history.push("/typesRisque/" + typeRisque._id + "/update")}>
                Détail
              </Button>
            </TableCell>
            <TableCell>
              <Button color="secondary" onClick={() => this.handleRemoveTypeRisque(typeRisque._id)}>
                Supprimer
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des types de risque
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("/typesRisque/add")}>
                Ajouter un type de risque
              </Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </section>
      </div>

    )
  }
}