import { get, removeApi, postApi, putApi } from '../tools/api';

export function loadTypesRisque() {
  return new Promise((resolve, reject) => {
    get("/api/types-risque")
      .then((response) => {
        if (response && response.types_risque) {
          return resolve(response.types_risque);
        } else {
          return reject("Erreur lors de la récupération des types de risque");
        }
      })
      .catch((response) => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la récupération des types de risque");
        }
      });
  });
}

export function removeTypeRisque(idTypeRisque) {
  return new Promise((resolve, reject) => {
    removeApi("/api/backend/types-risque/" + idTypeRisque)
      .then(() => resolve())
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la suppression du type de risque");
        }
      });
  });
}

export function addTypeRisque(nom) {
  return new Promise((resolve, reject) => {
    postApi("/api/backend/types-risque", {
      nom,
    })
      .then(response => {
        if (response?.type_risque) {
          return resolve(response.type_risque);
        }
        return reject("Erreur lors de l'ajout du type de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de l'ajout du type de risque");
        }
      });
  });
}

export function updateTypeRisque(id, nom) {
  return new Promise((resolve, reject) => {
    putApi("/api/backend/types-risque/" + id, {
      nom,
    })
      .then(response => {
        if (response?.type_risque) {
          return resolve(response.type_risque);
        }
        return reject("Erreur lors de la modification du type de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la modification du type de risque");
        }
      });
  });
}

export function getTypeRisque(id) {
  return new Promise((resolve, reject) => {
    get("/api/backend/types-risque/" + id)
      .then(response => {
        if (response?.type_risque) {
          return resolve(response.type_risque);
        }
        return reject("Erreur lors de la récupération du type de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la récupération du type de risque");
        }
      });
  });
}