import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { addCategorieRisque } from '../../actions/categoriesRisque';
import { loadRoles } from '../../actions/roles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { loadOriginesRisque } from '../../actions/originesRisque';
import { showParcelle } from '../../actions/parcelle';

export default class AddCategorieRisque extends React.Component {
    state = {
        nom: '',
        image: null,
        roles: [],
        originesRisque: [],
        originesRisqueSelected: [],
        perimetre_alerte: 0,
        duree_affichage_alerte: 0,
        public_cible_alerte: 0,
        loading: true,
        optionParcelleActive: false,
        activeQuePourParcelle: false,
    };

    componentDidMount() {
        Promise.all([loadRoles(), loadOriginesRisque(), showParcelle()])
            .then(values => {
                const roles = values[0];
                const originesRisque = values[1];
                this.setState({
                    roles: [{
                        nom: 'Tous les utilisateurs',
                        _id: -1
                    }].concat(roles),
                    originesRisque,
                    loading: false,
                    optionParcelleActive: values[2]
                });
            })
            .catch(error => sendMessageError(error));
    }

    handleInput = (identifiant, e) => {
        this.setState({
            [identifiant]: e.target.value,
        });
    };

    handleSwitchActiveParcelle = (e) => this.setState({ activeQuePourParcelle: e.target.checked });

    handleSubmit = () => {
        addCategorieRisque(
            this.state.nom,
            this.state.image,
            this.state.duree_affichage_alerte,
            this.state.perimetre_alerte,
            this.state.public_cible_alerte,
            this.state.originesRisqueSelected,
            null,
            this.state.activeQuePourParcelle,
        )
            .then(() => {
                sendMessageSuccess("Catégorie de risque bien ajoutée !");
                this.props.history.push("/categories-risque");
            })
            .catch(error => sendMessageError(error));
    };

    _handleImageChange = (e) => {
        e.preventDefault();
        this.setState({
            image: e.target.files[0],
        });
    };

    deleteImage = () => {
        this.setState({
            image: null,
        });
    };

    handleOrigineRisque = (e) => {
        this.setState({
            originesRisqueSelected: e.target.value,
        });
    };

    render() {

        if (this.state.loading) {
            return <div className="content-wrapper">
                <section className="content-header">
                    <CircularProgress />
                </section>
            </div>;
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Ajouter une catégorie de risque
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Button onClick={() => this.props.history.push("/categories-risque")}>
                                <i className="fa fa-dashboard"></i>
                                Catégories de risque
                            </Button>
                        </li>
                        <li className="active">
                            Ajouter une catégorie de risque
                        </li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Informations</h3>
                                </div>
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Nom"
                                                fullWidth={true}
                                                value={this.state.nom}
                                                onChange={e => this.handleInput("nom", e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                label="Périmètre d'alerte (en km)"
                                                type="number"
                                                fullWidth={true}
                                                value={this.state.perimetre_alerte}
                                                onChange={e => this.handleInput("perimetre_alerte", e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                label="Durée de l'affichage de l'alerte (en heure)"
                                                type="number"
                                                fullWidth={true}
                                                value={this.state.duree_affichage_alerte}
                                                onChange={e => this.handleInput("duree_affichage_alerte", e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormControl>
                                                <InputLabel>Utilisateurs cible</InputLabel>
                                                <Select
                                                    value={this.state.public_cible_alerte}
                                                    onChange={e => this.handleInput("public_cible_alerte", e)}
                                                >
                                                    {
                                                        this.state.roles.map((role) => {
                                                            return <MenuItem key={role._id} value={role._id}>
                                                                {role.nom}
                                                            </MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="form-group">
                                            <FormControl>
                                                <InputLabel>Origines de risque</InputLabel>
                                                <Select
                                                    multiple
                                                    value={this.state.originesRisqueSelected}
                                                    onChange={this.handleOrigineRisque}
                                                >
                                                    {
                                                        this.state.originesRisque.map((origineRisque) => {
                                                            return <MenuItem key={origineRisque._id} value={origineRisque._id}>
                                                                {origineRisque.nom}
                                                            </MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {
                                            this.state.optionParcelleActive && <div className="form-group">
                                                <FormControl>
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={this.state.activeQuePourParcelle}
                                                            onChange={e => this.handleSwitchActiveParcelle(e)}
                                                            color="primary" />}
                                                        label="Active si choix d'une parcelle"
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        this.state.image
                                                            ?
                                                            <div>
                                                                <span>{this.state.image.name}</span>
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    size="large"
                                                                    color="secondary"
                                                                    onClick={this.deleteImage}
                                                                >
                                                                    Supprimer l'image
                                                                </Button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        color="primary"
                                                        accept="image/*"
                                                        type="file"
                                                        onChange={this._handleImageChange}
                                                        id="icon-button-file"
                                                        style={{ display: 'none', }}
                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            size="large"
                                                            color="primary"
                                                        >
                                                            Ajouter une image
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Enregistrer
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
