import React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import { TableContainer } from '@material-ui/core';
import { loadTypeEtablissement, removeTypeEtablissement } from '../../actions/typeEtablissement';

export default class TypeEtablissement extends React.Component {
  state = {
    typesEtablissement: [],
  };

  componentDidMount() {
    this.__loadTypesEtablissement();
  }

  __loadTypesEtablissement = () => {
    const that = this;
    loadTypeEtablissement()
      .then(typesEtablissement => {
        that.setState({
          typesEtablissement,
        });
      });
  };

  handleRemoveTypeEtablissement = (idTypeEtablissement) => {
    removeTypeEtablissement(idTypeEtablissement)
      .then(() => {
        this.__loadTypesEtablissement();
        sendMessageSuccess("Type d'établissement bien supprimé")
      })
      .catch(error => sendMessageError(error));
  };

  render() {
    const { typesEtablissement } = this.state;
    let data = [];

    if (typesEtablissement?.length > 0) {
      for (const typeEtablissement of typesEtablissement) {
        data.push(
          <TableRow key={typeEtablissement._id}>
            <TableCell>{typeEtablissement.nom}</TableCell>
            <TableCell>
              <Button onClick={() => this.props.history.push("/types-etablissement/" + typeEtablissement._id + "/update")}>
                Détail
              </Button>
            </TableCell>
            <TableCell>
              <Button color="secondary" onClick={() => this.handleRemoveTypeEtablissement(typeEtablissement._id)}>
                Supprimer
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des types d'établissement
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("/types-etablissement/add")}>
                Ajouter un type d'établissement
              </Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </section>
      </div>

    )
  }
}