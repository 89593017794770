import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { getTypeRisque, updateTypeRisque } from "../../actions/typesRisque";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class UpdateTypeRisque extends React.Component {
    state = {
        idTypeRisque: '',
        nom: '',
        loading: true,
    };

    componentDidMount() {
        const idTypeRisque = this.props.match.params.id;
        if (idTypeRisque?.length === 0) {
            this.props.history.push("/typesRisque");
            return;
        }

        getTypeRisque(idTypeRisque)
            .then(typeRisque => {
                this.setState({
                    idTypeRisque,
                    nom: typeRisque.nom,
                    loading: false,
                });
            })
            .catch(error => sendMessageError(error));
    }

    handleInput = (identifiant, e) => {
        this.setState({
            [identifiant]: e.target.value,
        });
    };

    handleSubmit = () => {
        updateTypeRisque(this.state.idTypeRisque, this.state.nom)
            .then(() => {
                sendMessageSuccess("Type de risque bien modifié !");
                this.props.history.push("/typesRisque");
            })
            .catch(error => sendMessageError(error));
    };

    render() {
        const { nom,
            loading } = this.state;

        if (loading) {
            return <div className="content-wrapper">
                <section className="content-header">
                    <CircularProgress />
                </section>
            </div>;
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Modifier un type de risque
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Button onClick={() => this.props.history.push("/typesRisque")}>
                                <i className="fa fa-dashboard"></i>
                                Types de risques
                            </Button>
                        </li>
                        <li className="active">
                            Modifier un type de risque
                        </li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Informations</h3>
                                </div>
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Nom"
                                                value={nom}
                                                fullWidth={true}
                                                onChange={e => this.handleInput("nom", e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Modifier
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
