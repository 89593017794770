import React from 'react';
import { sendMessageError, sendMessageSuccess } from '../actions/toast';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { getContentAPropos, saveContentAPropos } from '../actions/apropos';

class APropos extends React.Component {
    state = {
        content: '',
    };

    componentDidMount() {
        getContentAPropos()
            .then(content => this.setState({ content }));
    }

    onChange = (event) => {
        const content = event.target.value;
        this.setState({ content });
    };

    save = () => {
        saveContentAPropos(this.state.content)
            .then(() => sendMessageSuccess("Contenu bien sauvegardé"))
            .catch(() => sendMessageError("Erreur lors de la sauvegarde du contenu"));
    };

    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <div className="row mb45">
                        <div className="col-md-12">
                            <TextField
                                id="content-a-propos"
                                multiline
                                label="A propos"
                                placeholder="Contenu"
                                rowsMax={30}
                                rows={10}
                                value={this.state.content}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Button
                                onClick={this.save}
                                color="primary"
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(APropos);