import { get } from "../tools/api";

export function loadVisibilites() {
    return new Promise((resolve, reject) => {
        get("/api/backend/visibilites")
            .then(visibilites => resolve(visibilites))
            .catch(response => {
                if(response?.error) {
                    return reject(response.error);
                }

                return reject("Erreur lors de la récupération des visibilités");
            })
    });
}