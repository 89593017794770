import React from 'react';
import Button from '@material-ui/core/Button';
import { loadRoles } from "../../actions/roles";
import { geocodeByPlaceId } from 'react-places-autocomplete'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { addUser } from '../../actions/users';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from "react-router-dom";
import GoogleAddress from '../GoogleAddress';

class AddUser extends React.Component {
  state = {
    nom: '',
    prenom: '',
    email: '',
    profession: '',
    adresse: '',
    telephone: '',
    password: '',
    idRole: '',
    ville: '',
    pays: '',
    code_postal: '',
    roles: [],
  };

  componentDidMount() {
    loadRoles()
      .then(roles => {
        this.setState({
          roles,
        });
      })
      .catch(error => sendMessageError(error));
  }

  handleSelectAdresse = (adresse, id) => {
    geocodeByPlaceId(id)
      .then(results => {
        if (results && results[0] && results[0].address_components && results[0].address_components.length > 0) {
          let street_number, route, locality, country, postal_code;

          for (const ac of results[0].address_components) {
            if (!ac.types || ac.types.length < 1) {
              continue;
            }

            if (ac.types.includes("street_number")) {
              street_number = ac.long_name;
            } else if (ac.types.includes("route")) {
              route = ac.long_name;
            } else if (ac.types.includes("locality")) {
              locality = ac.long_name;
            } else if (ac.types.includes("country")) {
              country = ac.long_name;
            } else if (ac.types.includes("postal_code")) {
              postal_code = ac.long_name;
            }
          }

          if (!locality || !country) {
            return false;
          }

          this.setState({
            adresse,
            pays: country,
            ville: locality,
            code_postal: postal_code,
          });
        }
        else {
          this.setState({
            adresse: '',
            pays: '',
            ville: '',
            code_postal: '',
          });
        }
      })
      .catch(error => {
        this.setState({
          adresse: '',
          pays: '',
          ville: '',
          code_postal: '',
        });
      });
  };

  handleInput = (name, e) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  handleSubmit = () => {
    addUser(
      this.state.nom,
      this.state.prenom,
      this.state.email,
      this.state.profession,
      this.state.adresse,
      this.state.telephone,
      this.state.password,
      this.state.idRole,
      this.state.ville,
      this.state.pays,
      this.state.code_postal,
    )
      .then(() => {
        sendMessageSuccess("Utilisateur bien ajouté !");
        this.props.history.push("/users");
      })
      .catch((error) => {
        sendMessageError(error);
      });
  };

  render() {
    const { roles } = this.state;

    let blockRoles = null;

    if (roles && roles.length > 0) {

      blockRoles = <FormControl>
      <InputLabel>Rôle</InputLabel>
      <Select
        value={this.state.idRole}
        onChange={e => this.handleInput("idRole", e)}
      >
        {
          roles.map((role) => {
            return <MenuItem key={role._id} value={role._id}>
              {role.nom}
            </MenuItem>
          })
        }
      </Select>
    </FormControl>
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Ajouter un utilisateur
          </h1>
          <ol className="breadcrumb">
            <li>
              <Button onClick={() => this.props.history.push("/users")}>
                <i className="fa fa-dashboard"></i>
                Utilisateurs
              </Button>
            </li>
            <li className="active">
              Ajouter un utilisateur
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Informations</h3>
                </div>
                <form role="form">
                  <div className="box-body">
                    <div className="row">
                        {blockRoles}
                    </div>
                    <div className="row">
                      <TextField
                        label="Nom"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("nom", e)}
                      />
                    </div>
                    <div className="row">
                      <TextField
                        label="Prénom"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("prenom", e)}
                      />
                    </div>
                    <div className="row">
                      <TextField
                        label="Email"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("email", e)}
                      />
                    </div>
                    <div className="row">
                      <TextField
                        label="Mot de passe"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("password", e)}
                        type="password"
                      />
                    </div>
                    <div className="row">
                      <TextField
                        label="Code"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("profession", e)}
                      />
                    </div>
                    <div className="row">
                      <GoogleAddress defaultAddress={this.state.adresse} handleAddress={this.handleSelectAdresse}/>
                    </div>
                    <div className="row">
                      <TextField
                        label="Téléphone"
                        fullWidth={true}
                        onChange={(e) => this.handleInput("telephone", e)}
                      />
                    </div>
                  </div>

                  <div className="box-footer text-center">
                    <Button
                      onClick={this.handleSubmit}
                      color="primary"
                    >
                      Enregistrer
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(AddUser);