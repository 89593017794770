import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getRisque, updateRisque, removeImage } from '../../actions/risques';
import { loadCategoriesRisque } from '../../actions/categoriesRisque';
import { loadTypesRisque } from '../../actions/typesRisque';
import { loadOriginesRisque } from '../../actions/originesRisque';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { url } from '../../tools/api';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GoogleAddress from '../GoogleAddress';
import { loadVisibilites } from '../../actions/visibilites';

const styles = {
  button: {
    margin: 12,
  },
  exampleImageInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
};

export default class UpdateRisque extends React.Component {
  state = {
    idRisque: "",
    description: '',
    adresse: '',
    lat: 0.0,
    lon: 0.0,
    categoriesRisque: [],
    typesRisque: [],
    originesRisque: [],
    visibilites: [],
    idCategorieRisque: '',
    idTypeRisque: '',
    idOrigineRisque: '',
    urlPhoto: '',
    image: null,
    idVisibilite: "",
  };

  componentDidMount() {
    const that = this;

    const idRisque = this.props.match.params.id;
    if (!idRisque || idRisque.length === 0) {
      this.props.history.push("/risques");
      return;
    }

    that.setState({
      idRisque
    }, () => {
      loadTypesRisque()
        .then((typesRisque) => {
          that.setState({
            typesRisque,
          });
        })
        .catch(error => sendMessageError(error));

      loadCategoriesRisque()
        .then((categoriesRisque) => {
          that.setState({
            categoriesRisque,
          });
        })
        .catch(error => sendMessageError(error));

      loadOriginesRisque()
        .then((originesRisque) => {
          that.setState({
            originesRisque,
          });
        })
        .catch(error => sendMessageError(error));

      loadVisibilites()
        .then(visibilites => this.setState({ visibilites }))
        .catch(error => sendMessageError(error));

      that.__loadRisque();
    });
  }

  __loadRisque = () => {
    const that = this;
    getRisque(that.state.idRisque)
      .then((risque) => {
        if (risque && risque.categorie_risque && risque.type_risque && risque.origine_risque && risque.location) {
          that.__affecteRisqueState(risque);
        } else {
          sendMessageError("Erreur lors de la récupération des données du risque");
        }
      })
      .catch(error => sendMessageError(error));
  };

  __affecteRisqueState = (risque) => {
    const that = this;
    that.setState({
      description: risque.description,
      adresse: risque.adresse,
      lat: risque.location.coordinates[1],
      lon: risque.location.coordinates[0],
      idCategorieRisque: risque.categorie_risque.id_categorie_risque,
      idTypeRisque: risque.type_risque.id_type_risque,
      idOrigineRisque: risque.origine_risque.id_origine_risque,
      idVisibilite: risque.visibilite,
      urlPhoto: risque.url_photo,
    });
  };

  handleInput = (identifiant, e) => {
    this.setState({
      [identifiant]: e.target.value,
    });
  };

  handleSelectAdresse = (adresse, latLng) => {
    this.setState({
      adresse: adresse,
      lat: latLng.lat,
      lon: latLng.lng
    });
  };

  handleSubmit = () => {
    updateRisque(
      this.state.idRisque,
      this.state.idTypeRisque,
      this.state.idCategorieRisque,
      this.state.idOrigineRisque,
      this.state.idVisibilite,
      this.state.adresse,
      this.state.lat,
      this.state.lon,
      this.state.description,
      this.state.image,
    )
      .then(() => {
        sendMessageSuccess("Risque bien modifié !");
        this.props.history.push("/risques");
      })
      .catch(error => sendMessageError(error));
  };

  _handleImageChange = (e) => {
    e.preventDefault();
    this.setState({
      image: e.target.files[0],
    });
  };

  handleRemoveImage = () => {
    const that = this;
    removeImage(that.state.idRisque)
      .then((risque) => {
        if (risque && risque.categorie_risque && risque.type_risque && risque.origine_risque && risque.location) {
          that.__affecteRisqueState(risque);
        }
      })
      .catch(error => sendMessageError(error));
  };

  render() {
    const { typesRisque, categoriesRisque, originesRisque, visibilites } = this.state;

    let blockTypesRisque = null;
    let blockCategoriesRisque = null;
    let blockOriginesRisque = null;
    let btnDeleteImage = null;
    let blockImageRisque = null;

    if (typesRisque?.length > 0) {
      blockTypesRisque = <FormControl>
        <InputLabel>Type de risque</InputLabel>
        <Select
          autoWidth={true}
          value={this.state.idTypeRisque}
          onChange={e => this.handleInput("idTypeRisque", e)}
        >
          {
            typesRisque.map((t) => <MenuItem key={t._id} value={t._id}>
              {t.nom}
            </MenuItem>
            )
          }
        </Select>
      </FormControl>;
    }

    if (categoriesRisque?.length > 0) {
      blockCategoriesRisque = <FormControl>
        <InputLabel>Catégorie</InputLabel>
        <Select
          autoWidth={true}
          value={this.state.idCategorieRisque}
          onChange={e => this.handleInput("idCategorieRisque", e)}
        >
          {
            categoriesRisque.map((t) => <MenuItem key={t._id} value={t._id}>
              {t.nom}
            </MenuItem>
            )
          }
        </Select>
      </FormControl>;
    }

    if (originesRisque?.length > 0) {
      blockOriginesRisque = <FormControl>
        <InputLabel>Type d'origine</InputLabel>
        <Select
          autoWidth={true}
          value={this.state.idOrigineRisque}
          onChange={e => this.handleInput("idOrigineRisque", e)}
        >
          {
            originesRisque.map((t) => <MenuItem key={t._id} value={t._id}>
              {t.nom}
            </MenuItem>
            )
          }
        </Select>
      </FormControl>;
    }

    let blockVisibilites = null;
    if (visibilites?.length > 0) {
      blockVisibilites = <FormControl>
        <InputLabel>Visibilité</InputLabel>
        <Select
          autoWidth={true}
          value={this.state.idVisibilite}
          onChange={e => this.handleInput("idVisibilite", e)}
        >
          {
            visibilites.map((t) => <MenuItem key={t._id} value={t._id}>
              {t.nom}
            </MenuItem>
            )
          }
        </Select>
      </FormControl>;
    }

    if (this.state.urlPhoto?.length > 0) {
      blockImageRisque = <div className="row">
        <div className="col-md-12">
          <img className="img-responsive pad imageRisque" src={url + "/static/images/risques/" + this.state.idRisque}
            alt="Photo" />
        </div>
      </div>;

      btnDeleteImage = <div className="col-md-6">
        <Button
          style={styles.button}
          color="secondary"
          onClick={this.handleRemoveImage}
        >
          Supprimer l'image
        </Button>
      </div>;
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Modifier un risque
          </h1>
          <ol className="breadcrumb">
            <li>
              <Button onClick={() => this.props.history.push("/risques")}>
                <i className="fa fa-dashboard"></i>
                Risques
              </Button>
            </li>
            <li className="active">
              Modifier un risque
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Informations</h3>
                </div>
                <form role="form">
                  <div className="box-body">
                    <div className="form-group">
                      {blockCategoriesRisque}
                    </div>
                    <div className="form-group">
                      {blockOriginesRisque}
                    </div>
                    <div className="form-group">
                      {blockVisibilites}
                    </div>
                    <div className="form-group">
                      <GoogleAddress defaultAddress={this.state.adresse} handleAddress={this.handleSelectAdresse} />
                    </div>
                    <div className="form-group">
                      <InputLabel>Description</InputLabel>
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("description", e)}
                        value={this.state.description}
                      />
                    </div>
                    <div className="form-group">
                      {blockTypesRisque}
                    </div>
                    <div className="form-group">
                      {blockImageRisque}
                      <div className="row">
                        <div className="col-md-6">
                          <Button
                            style={styles.button}
                          >
                            <input type="file" style={styles.exampleImageInput} onChange={this._handleImageChange} />
                          </Button>
                        </div>
                        {btnDeleteImage}
                      </div>
                    </div>
                  </div>

                  <div className="box-footer text-center">
                    <Button
                      onClick={this.handleSubmit}
                      color="primary"
                    >
                      Enregistrer
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}