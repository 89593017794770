import React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import { TableContainer } from '@material-ui/core';
import { removeOrigineRisque, loadOriginesRisque } from '../../actions/originesRisque';

export default class OrigineRisques extends React.Component {
  state = {
    origineRisques: [],
  };

  componentDidMount() {
    this.__loadOriginesRisque();
  }

  __loadOriginesRisque = () => {
    const that = this;
    loadOriginesRisque()
      .then((origineRisques) => {
        that.setState({
          origineRisques,
        });
      });
  };

  handleRemoveOrigineRisque = (id) => {
    removeOrigineRisque(id)
      .then(() => {
        this.__loadOriginesRisque();
        sendMessageSuccess("L'origine de risque bien supprimée")
      })
      .catch(error => sendMessageError(error));
  };

  render() {
    const { origineRisques } = this.state;
    let data = [];

    if (origineRisques?.length > 0) {
      for (const origineRisque of origineRisques) {
        data.push(
          <TableRow key={origineRisque._id}>
            <TableCell>{origineRisque.nom}</TableCell>
            <TableCell>
              <Button onClick={() => this.props.history.push("/originesRisque/" + origineRisque._id + "/update")}>
                Détail
              </Button>
            </TableCell>
            <TableCell>
              <Button color="secondary" onClick={() => this.handleRemoveOrigineRisque(origineRisque._id)}>
                Supprimer
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des origines de risque
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("/originesRisque/add")}>
                Ajouter une origine de risque
              </Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </section>
      </div>
    )
  }
}