import { get, removeApi, downloadFileAsText, postMultipartApi, putMultipartApi } from '../tools/api';
import FileSaver from 'file-saver';

export function importCsv(file) {
    return new Promise((resolve, reject) => {
        postMultipartApi("/api/backend/risques/import", [], file)
            .then(() => resolve())
            .catch(() => reject());
    });
}

export function exportCsv() {
    return new Promise((resolve, reject) => {
        downloadFileAsText("/api/backend/risques/export")
            .then((file, type) => {
                if (!file) {
                    return reject("Erreur lors du téléchargement du fichier");
                }

                const blob = new Blob([file], { type: type });
                FileSaver.saveAs(blob, "risques_csv.csv");
                return resolve();
            })
            .catch(() => {
                return reject("Erreur inconnue");
            });
    });
}

export function removeImage(idRisque) {
    return new Promise((resolve, reject) => {
        removeApi("/api/backend/risques/" + idRisque + "/image")
            .then((response) => {
                if (response && response.risque) {
                    return resolve(response.risque);
                }
                return reject("L'image a du être supprimée")
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la supression de l'image");
            });
    });
}

export function loadRisques(offset, filtreIdCategorieRisque, filtreIdTypeRisque) {
    return new Promise((resolve, reject) => {
        get('/api/backend/risques', {
            offset: offset,
            filtreIdCategorieRisque,
            filtreIdTypeRisque,
        })
            .then((response) => {
                if (response && response.risques) {
                    return resolve(response.risques);
                }
                return reject("Erreur lors de la récupération des risques");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la récupération des risques");
            });
    });
}

export function addRisque(
    idTypeRisque,
    idCategorieRisque,
    idOrigineRisque,
    adresse,
    lat,
    lon,
    description,
    image, ) {
    return new Promise((resolve, reject) => {

        if (!idCategorieRisque || idCategorieRisque.length === 0) {
            return reject("Vous devez selectionner une catégorie de risque");
        }

        if (!adresse || adresse.length === 0) {
            return reject("Vous devez saisir une adresse");
        }

        if (!lat || lat === 0.0) {
            return reject("Erreur lors de la récupération de la latitude de l'adresse");
        }

        if (!lon || lon === 0.0) {
            return reject("Erreur lors de la récupération de la longitude de l'adresse");
        }

        if (!description || description.length === 0) {
            return reject("Vous devez saisir une description");
        }

        postMultipartApi("/api/risques", [{
            key: "risque",
            value: JSON.stringify({
                type_risque: {
                    id_type_risque: idTypeRisque,
                },
                categorie_risque: {
                    id_categorie_risque: idCategorieRisque,
                },
                origine_risque: {
                    id_origine_risque: idOrigineRisque,
                },
                description: description,
                location: {
                    coordinates: [lon, lat],
                },
                adresse: adresse,
            }),
        }], image)
            .then((response) => {
                if (response && response.risque) {
                    return resolve();
                }
                return reject("Erreur lors de la création du risque");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la création du risque");
            });
    });
}

export function updateRisque(idRisque,
    idTypeRisque,
    idCategorieRisque,
    idOrigineRisque,
    idVisibilite,
    adresse,
    lat,
    lon,
    description,
    image) {
    return new Promise((resolve, reject) => {
        if (!idRisque || idRisque.length === 0) {
            return reject("Erreur lors de la récupération du type de risque");
        }

        if (!idCategorieRisque || idCategorieRisque.length === 0) {
            return reject("Vous devez selectionner une catégorie de risque");
        }

        if (!adresse || adresse.length === 0) {
            return reject("Vous devez saisir une adresse");
        }

        if (!lat || lat === 0.0) {
            return reject("Erreur lors de la récupération de la latitude de l'adresse");
        }

        if (!lon || lon === 0.0) {
            return reject("Erreur lors de la récupération de la longitude de l'adresse");
        }

        if (!description || description.length === 0) {
            return reject("Vous devez saisir une description");
        }

        putMultipartApi("/api/backend/risques/" + idRisque, [{
            key: "risque",
            value: JSON.stringify({
                categorie_risque: {
                    id_categorie_risque: idCategorieRisque,
                },
                type_risque: {
                    id_type_risque: idTypeRisque,
                },
                origine_risque: {
                    id_origine_risque: idOrigineRisque,
                },
                description: description,
                location: {
                    type: "Point",
                    coordinates: [lon, lat],
                },
                adresse: adresse,
                visibilite: idVisibilite,
            }),
        }], image)
            .then((response) => {
                if (response && response.risque) {
                    return resolve();
                }
                return reject("Erreur lors de la modification du risque");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la modification du risque");
            });
    });
}

export function getRisque(idRisque) {
    return new Promise((resolve, reject) => {
        if (!idRisque || idRisque.length === 0) {
            return reject("Erreur lors de la récupération de l'identification du risque");
        }

        get("/api/backend/risques/" + idRisque)
            .then((response) => {
                if (response && response.risque) {
                    return resolve(response.risque);
                }
                return reject("Erreur lors de la récupération du risque");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la récupération du risque");
            });
    });
}

export function removeRisque(idRisque) {
    return new Promise((resolve, reject) => {
        if (!idRisque || idRisque.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant du risque");
        }

        removeApi("/api/risques/" + idRisque)
            .then(() => resolve())
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la suppression du risque");
            });
    });
}

export function countRisques() {
    return new Promise((resolve, reject) => {
        get("/api/backend/risques/count")
            .then((response) => {
                if (response && response.count !== undefined) {
                    return resolve(response.count);
                }
                return reject("Erreur lors de la récupération du nombre de risque");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                }
                return reject("Erreur lors de la récupération du nombre de risque");
            });
    });
}
