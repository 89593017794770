import { get, putApi } from '../tools/api';

let __settings = null;
let __handler = null;

export function getSettings() {
    return new Promise((resolve, reject) => {
        get("/api/backend/settings")
            .then(settings => {
                __settings = settings;
                resolve(settings);

                if (__handler) {
                    __handler(settings);
                }
            })
            .catch(error => reject(error?.error ? error.error : "Erreur lors de la récupération du paramétrage"))
    });
}

export function updateSettings(settings) {
    return new Promise((resolve, reject) => {
        putApi("/api/backend/settings", settings)
            .then(() => resolve())
            .catch(error => reject(error?.error ? error.error : "Erreur lors de la modification du paramétrage"))
    });
}

export function getSettingsSync() {
    return __settings;
}

export function setSettingHandler(handler) {
    __handler = handler;
}