import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { updateTypeRisque } from "../../actions/typesRisque";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOrigineRisque, updateOrigineRisque } from '../../actions/originesRisque';

export default class UpdateOrigineRisque extends React.Component {
    state = {
        idOrigineRisque: '',
        nom: '',
        loading: true,
    };

    componentDidMount() {
        const idOrigineRisque = this.props.match.params.id;
        if (idOrigineRisque?.length === 0) {
            this.props.history.push("/originesRisque");
            return;
        }

        getOrigineRisque(idOrigineRisque)
            .then(origineRisque => {
                this.setState({
                    idOrigineRisque,
                    nom: origineRisque.nom,
                    loading: false,
                });
            })
            .catch(error => sendMessageError(error));
    }

    handleInput = (identifiant, e) => {
        this.setState({
            [identifiant]: e.target.value,
        });
    };

    handleSubmit = () => {
        updateOrigineRisque(this.state.idOrigineRisque, this.state.nom)
            .then(() => {
                sendMessageSuccess("Origine de risque bien modifiée !");
                this.props.history.push("/originesRisque");
            })
            .catch(error => sendMessageError(error));
    };

    render() {
        const { nom, loading } = this.state;

        if (loading) {
            return <div className="content-wrapper">
                <section className="content-header">
                    <CircularProgress />
                </section>
            </div>;
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Modifier l'origine de risque
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Button onClick={() => this.props.history.push("/originesRisque")}>
                                <i className="fa fa-dashboard"></i>
                                Origines de risques
                            </Button>
                        </li>
                        <li className="active">
                            Modifier l'origine de risque
                        </li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Informations</h3>
                                </div>
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Nom"
                                                value={nom}
                                                fullWidth={true}
                                                onChange={e => this.handleInput("nom", e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Modifier
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
