import 'typeface-roboto'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/layout/App';
import { createHashHistory } from "history";

const history = createHashHistory();

ReactDOM.render(
  <App history={history}/>,
  document.getElementById('root')
);