import React from 'react';
import Button from '@material-ui/core/Button';
import { loadRoles } from "../../actions/roles";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { getUser, updateUser } from '../../actions/users';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import GoogleAddress from '../GoogleAddress';
import { getParcelleByUser, showParcelle } from '../../actions/parcelle';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

export default class UpdateUser extends React.Component {
  state = {
    id_user: null,
    nom: '',
    prenom: '',
    profession: '',
    adresse: '',
    telephone: '',
    idRole: '',
    ville: '',
    pays: '',
    code_postal: '',
    roles: [],
    parcelles: null,
    parcelleToShow: null,
    positionDomicile: null,
  };

  componentDidMount() {
    const that = this;

    const idUser = this.props.match.params.id;
    if (!idUser || idUser.length === 0) {
      this.props.history.push("/users");
    }

    this.setState({
      id_user: idUser
    }, () => {
      loadRoles()
        .then(roles => {
          getUser(that.state.id_user)
            .then((user) => {
              showParcelle()
                .then(useParcelle => {
                  if (!useParcelle) {
                    that.setState({
                      nom: user.nom,
                      prenom: user.prenom,
                      profession: user.job_name,
                      adresse: user.adresse,
                      telephone: user.telephone,
                      idRole: user.role.id_role,
                      ville: user.ville,
                      pays: user.pays,
                      code_postal: user.code_postal,
                      positionDomicile: user.positionDomicile,
                      roles,
                    });
                  } else {
                    getParcelleByUser(that.state.id_user)
                      .then(parcelles => {
                        that.setState({
                          nom: user.nom,
                          prenom: user.prenom,
                          profession: user.job_name,
                          adresse: user.adresse,
                          telephone: user.telephone,
                          idRole: user.role.id_role,
                          ville: user.ville,
                          pays: user.pays,
                          code_postal: user.code_postal,
                          roles,
                          parcelles: parcelles?.length ? parcelles : null,
                          positionDomicile: user.positionDomicile,
                        });
                      })
                      .catch(err => sendMessageError(err));
                  }
                })
            })
            .catch(error => sendMessageError(error));
        })
        .catch(error => sendMessageError(error));
    });
  }

  handleSelectAdresse = (adresse, latLng, ville, pays, code_postal) => {
    this.setState({
      adresse,
      pays,
      ville,
      code_postal,
      positionDomicile: {
        type: "Point",
        coordinates: [latLng.lng, latLng.lat]
      }
    });
  };

  handleInput = (identifiant, e) => {
    this.setState({
      [identifiant]: e.target.value,
    });
  };

  handleSubmit = () => {
    updateUser(
      this.state.id_user,
      this.state.nom,
      this.state.prenom,
      this.state.profession,
      this.state.adresse,
      this.state.telephone,
      this.state.idRole,
      this.state.ville,
      this.state.pays,
      this.state.code_postal,
      this.state.positionDomicile,
    )
      .then(() => {
        sendMessageSuccess("Utilisateur bien modifié !")
        this.props.history.push("/users");
      })
      .catch(error => sendMessageError(error));
  };

  showParcelle = (parcelleToShow) => this.setState({ parcelleToShow });

  render() {
    const { roles, parcelles, parcelleToShow } = this.state;
    let blockRoles = null;
    let blockParcelle = null;

    if (roles && roles.length > 0) {
      blockRoles =
        <FormControl>
          <InputLabel>Rôle</InputLabel>
          <Select
            autoWidth={true}
            value={this.state.idRole}
            onChange={(e) => this.handleInput("idRole", e)}
          >
            {
              roles.map((role) => <MenuItem key={role._id} value={role._id}>
                {role.nom}
              </MenuItem>
              )
            }
          </Select>
        </FormControl>;
    }

    if (parcelles) {

      const data = [];
      for (const parcelle of parcelles) {
        data.push(
          <TableRow key={parcelle._id}>
            <TableCell>{parcelle.nom}</TableCell>
            <TableCell>
              <Button color="secondary" onClick={() => this.showParcelle(parcelle)}>Afficher sur une carte</Button>
            </TableCell>
          </TableRow>
        );
      }

      let mapParcelle = null;

      if (parcelleToShow) {
        const center = {
          lat: parcelleToShow.location.coordinates[1],
          lng: parcelleToShow.location.coordinates[0]
        };
        
        mapParcelle =
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <MapContainer style={{ height: '50vh', width: '100wh' }} center={center} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={center} />
              </MapContainer>
            </div>
          </div>
          ;
      }

      blockParcelle = <div className="row">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <h3 className="box-title">
              Parcelles
          </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <Paper>
              <TableContainer>
                <Table selectable={"false"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        {
          mapParcelle
        }
      </div>;
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Modifier un utilisateur
          </h1>
          <ol className="breadcrumb">
            <li>
              <Button onClick={() => this.props.history.push("/users")}>
                <i className="fa fa-dashboard"></i>
                Utilisateurs
              </Button>
            </li>
            <li className="active">
              Modifier un utilisateur
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Informations</h3>
                </div>
                <form role="form">
                  <div className="box-body">
                    <div className="form-group">
                      {blockRoles}
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("nom", e)}
                        value={this.state.nom}
                        label="Nom"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("prenom", e)}
                        value={this.state.prenom}
                        label="Prénom"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("profession", e)}
                        value={this.state.profession}
                        label="Code"
                      />
                    </div>
                    <div className="form-group">
                      <GoogleAddress defaultAddress={this.state.adresse} handleAddress={this.handleSelectAdresse} />
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("telephone", e)}
                        value={this.state.telephone}
                        label="Telephone"
                      />
                    </div>
                  </div>

                  <div className="box-footer text-center">
                    <Button
                      onClick={this.handleSubmit}
                      color="primary"
                    >
                      Enregistrer
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {blockParcelle}
        </section>
      </div>
    )
  }
}
