import { get, postApi, removeApi, putApi, downloadFileAsText, postMultipartApi } from '../tools/api';
import FileSaver from 'file-saver';

const validator = require("email-validator");

export function importCsv(file) {
    return new Promise((resolve, reject) => {
        postMultipartApi("/api/backend/users/import", [], file)
            .then((response) => {
                return resolve();
            })
            .catch((response) => {
                return reject();
            });
    });
}

export function exportCsv() {
    return new Promise((resolve, reject) => {
        downloadFileAsText("/api/backend/users/export")
            .then((file, type) => {
                if (!file) {
                    return reject("Erreur lors du téléchargement du fichier");
                }

                const blob = new Blob([file], { type: type });
                FileSaver.saveAs(blob, "utilisateurs_csv.csv");
                return resolve();
            })
            .catch(() => {
                return reject("Erreur inconnue");
            });
    });
}

export function getUser(idUser) {
    return new Promise((resolve, reject) => {
        if (!idUser || idUser.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'utilisateur");
        }

        get("/api/backend/users/" + idUser)
            .then((response) => {
                if (response && response.user) {
                    return resolve(response.user);
                } else {
                    return reject("Erreur lors de la récupération de l'utilisateur");
                }
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                } else {
                    return reject("Erreur lors de la récupération de l'utilisateur");
                }
            })
    });
}

export function updateUser(
    idUser,
    nom,
    prenom,
    job_name,
    adresse,
    telephone,
    idRole,
    ville,
    pays,
    code_postal,
    positionDomicile ) {
    return new Promise((resolve, reject) => {
        if (idUser?.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'utilisateur");
        }

        if (nom?.length === 0) {
            return reject("Vous devez saisir un nom");
        }

        if (prenom?.length === 0) {
            return reject("Vous devez saisir un prénom");
        }

        if (idRole?.length === 0) {
            return reject("Vous devez selectionner un role");
        }

        putApi("/api/backend/users/" + idUser, {
            nom,
            prenom,
            ville,
            pays,
            code_postal,
            adresse,
            role: {
                id_role: idRole,
            },
            job_name,
            telephone,
            positionDomicile,
        })
            .then((response) => {
                if (response && response.user) {
                    return resolve(response.user);
                }

                return reject("Erreur lors de la modification du compte utilisateur");
            })
            .catch((response) =>
                reject(response && response.error ? response.error : "Erreur lors de la modification du compte utilisateur"));
    });
}

export function loadUsers(offset, idRole, prefix) {
    return new Promise((resolve, reject) => {
        get('/api/backend/users', {
            offset,
            idRole,
            prefix,
        })
            .then((response) => {
                if (response && response.users) {
                    return resolve(response.users);
                }

                return reject();
            })
            .catch((response) => reject(response && response.error ? response.error : ""));
    });
}

export function addUser(nom,
    prenom,
    email,
    profession,
    adresse,
    telephone,
    password,
    idRole,
    ville,
    pays,
    codePostal ) {
    return new Promise((resolve, reject) => {
        if (!nom || nom.length === 0) {
            return reject("Vous devez saisir un nom");
        }

        if (!prenom || prenom.length === 0) {
            return reject("Vous devez saisir un prénom");
        }

        if (!email || email.length === 0) {
            return reject("Vous devez saisir un email");
        }

        if (!validator.validate(email)) {
            return reject("Votre email n'est pas valide");
        }

        if (!password || password.length === 0) {
            return reject("Vous devez saisir un mot de passe");
        }

        if (!idRole || idRole.length === 0) {
            return reject("Vous devez selectionner un role");
        }

        postApi("/api/backend/users", {
            nom: nom,
            prenom: prenom,
            email: email,
            password: password,
            ville: ville,
            pays: pays,
            code_postal: codePostal,
            adresse: adresse,
            role: {
                id_role: idRole,
            },
            job_name: profession,
        })
            .then((response) => {
                if (response && response.user) {
                    return resolve();
                }
                return reject("Erreur lors de la création du compte");
            })
            .catch((response) => reject(response && response.error ? response.error : "Erreur lors de la création du compte"));
    });
}

export function removeUser(idUser) {
    return new Promise((resolve, reject) => {
        if (!idUser || idUser.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'utilisateur");
        }

        removeApi("/api/backend/users/" + idUser)
            .then(() => {
                return resolve();
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                } else {
                    return reject("Erreur lors de la suppression de l'utilisateur");
                }
            });
    });
}

export function countUsers() {
    return new Promise((resolve, reject) => {
        get("/api/backend/users/count")
            .then((response) => {
                if (response && response.count !== undefined) {
                    return resolve(response.count);
                }
                return reject("Erreur lors de la récupération du nombre d'utilisateur");
            })
            .catch((response) => {
                if (response && response.error) {
                    return reject(response.error);
                } else {
                    return reject("Erreur lors de la récupération du nombre d'utilisateur");
                }
            });
    });
}
