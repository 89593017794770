import { get, removeApi, putApi, postApi } from '../tools/api';

export function loadOriginesRisque() {
  return new Promise((resolve, reject) => {
    get("/api/origines-risque")
      .then((response) => {
        if (response && response.origines_risque) {
          return resolve(response.origines_risque);
        } else {
          return reject("Erreur lors de la récupération des origines de risque");
        }
      })
      .catch((response) => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la récupération des origines de risque");
        }
      });
  });
}

export function removeOrigineRisque(idOrigineRisque) {
  return new Promise((resolve, reject) => {
    removeApi("/api/backend/origines-risque/" + idOrigineRisque)
      .then(() => resolve())
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la suppression de l'origine de risque");
        }
      });
  });
}

export function addOrigineRisque(nom) {
  return new Promise((resolve, reject) => {
    postApi("/api/backend/origines-risque", {
      nom,
    })
      .then(response => {
        if (response?.origine_risque) {
          return resolve(response.origine_risque);
        }
        return reject("Erreur lors de l'ajout de l'origine de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de l'ajout de l'origine de risque");
        }
      });
  });
}

export function updateOrigineRisque(id, nom) {
  return new Promise((resolve, reject) => {
    putApi("/api/backend/origines-risque/" + id, {
      nom,
    })
      .then(response => {
        if (response?.origine_risque) {
          return resolve(response.origine_risque);
        }
        return reject("Erreur lors de la modification de l'origine de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la modification de l'origine de risque");
        }
      });
  });
}

export function getOrigineRisque(id) {
  return new Promise((resolve, reject) => {
    get("/api/backend/origines-risque/" + id)
      .then(response => {
        if (response?.origine_risque) {
          return resolve(response.origine_risque);
        }
        return reject("Erreur lors de la récupération de l'origine de risque");
      })
      .catch(response => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la récupération de l'origine de risque");
        }
      });
  });
}