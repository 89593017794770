import {get} from '../tools/api';

export function loadRoles() {
  return new Promise((resolve, reject) => {
    get('/api/backend/roles')
    .then((response) => {
      if (response && response.roles) {
        return resolve(response.roles);
      } 

      return reject();
    })
    .catch((response) => {
      if (response && response.error) {
        return reject(response.error);
      }
      return reject();
    });
  });
}