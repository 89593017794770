import { get } from '../tools/api';

export function showParcelle() {
    return new Promise((resolve, reject) => {
        get("/api/parcelles/access")
            .then(response => resolve(response))
            .then(response => reject(response?.error));
    });
}

export function getParcelleByUser(idUser) {
    return new Promise((resolve, reject) => {
        get("/api/backend/parcelles/" + idUser)
            .then(response => resolve(response))
            .then(response => reject(response?.error));
    })
}