import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { login } from '../../actions/auth';
import { getToken } from '../../tools/localStorage';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { sendMessageError } from '../../actions/toast';

const styles = {
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: 300,
  },
  paper: {
    padding: 20,
  },
  form: {
    paddingTop: 10,
  },
  button: {
    paddingTop: 30,
    textAlign: 'center'
  }
};

export default class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handlePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleConnexion = () => {
    login(this.state.email, this.state.password)
      .then(() => this.props.history.push("/users"))
      .catch(error => sendMessageError(error));
  };

  render() {
    return (
      <Grid container style={styles.root}>
        <Paper style={styles.paper}>
          <Typography variant="h5">
            Connectez-vous pour accèder à l'espace administrateur
          </Typography>
          <Grid item xs={12} spacing={5} style={styles.form}>
            <TextField
              label="Email"
              style={styles.form}
              onChange={this.handleEmail}
            />
          </Grid>
          <Grid item xs={12} spacing={5} style={styles.form}>
            <TextField
              label="Mot de passe"

              onChange={this.handlePassword}
              type="password"
            />
          </Grid>
          <Grid item xs={12} spacing={5} style={styles.button}>
            <Button
              color="primary"
              variant="contained"
              className={styles.form}
              onClick={this.handleConnexion}
            >
              Connexion
          </Button>
          </Grid>
        </Paper>
      </Grid >
    )
  }
}
