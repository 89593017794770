import React from 'react';
import { isConnected } from "../../tools/auth";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { addRisque } from '../../actions/risques';
import { loadCategoriesRisque } from '../../actions/categoriesRisque';
import { loadOriginesRisque } from '../../actions/originesRisque';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { loadTypesRisque } from "../../actions/typesRisque";
import GoogleAddress from '../GoogleAddress';

export default class AddRisque extends React.Component {
    constructor(props) {
        super(props);

        if (!isConnected()) {
            //Redirect
        }

        this.state = {
            description: '',
            adresse: '',
            lat: 0.0,
            lon: 0.0,
            categoriesRisque: [],
            typesRisque: [],
            originesRisque: [],
            idCategorieRisque: '',
            idTypeRisque: '',
            idOrigineRisque: '',
            image: null,
        };
    }

    componentDidMount() {
        loadCategoriesRisque()
            .then((categoriesRisque) => {
                this.setState({
                    categoriesRisque,
                });
            })
            .catch(error => sendMessageError(error));

        loadOriginesRisque()
            .then((originesRisque) => {
                this.setState({
                    originesRisque,
                });
            })
            .catch(error => sendMessageError(error));

        loadTypesRisque()
            .then(typesRisque => {
                this.setState({
                    typesRisque,
                });
            })
            .catch(error => sendMessageError(error));
    }

    handleInput = (identifiant, e) => {
        this.setState({
            [identifiant]: e.target.value,
        });
    };

    handleSelectAdresse = (adresse, latLng) => {
        this.setState({
            adresse: adresse,
            lat: latLng.lat,
            lon: latLng.lng
        });
    };

    handleSubmit = () => {
        addRisque(
            this.state.idTypeRisque,
            this.state.idCategorieRisque,
            this.state.idOrigineRisque,
            this.state.adresse,
            this.state.lat,
            this.state.lon,
            this.state.description,
            this.state.image,
        )
            .then(() => {
                sendMessageSuccess("Risque bien ajouté !");
                this.props.history.push("/risques");
            })
            .catch(error => sendMessageError(error));
    };

    _handleImageChange = (e) => {
        e.preventDefault();

        let file = e.target.files[0];
        console.log(file);

        this.setState({
            image: file,
        });
    };

    deleteImage = () => {
        this.setState({
            image: null,
        });
    };

    render() {
        const { categoriesRisque, originesRisque, typesRisque } = this.state;
        let blockCategoriesRisque = null;
        let blockOriginesRisque = null;
        let blockTypesRisque = null;

        if (categoriesRisque?.length > 0) {
            blockCategoriesRisque = <FormControl>
                <InputLabel>Catégorie du risque</InputLabel>
                <Select
                    autoWidth={true}
                    value={this.state.idCategorieRisque}
                    onChange={e => this.handleInput("idCategorieRisque", e)}
                >
                    {
                        categoriesRisque.map((t) => <MenuItem key={t._id} value={t._id}>{t.nom}</MenuItem>)
                    }
                </Select>
            </FormControl>;
        }

        if (originesRisque?.length > 0) {
            blockOriginesRisque = <FormControl>
                <InputLabel>Origine du risque</InputLabel>
                <Select
                    autoWidth={true}
                    value={this.state.idOrigineRisque}
                    onChange={e => this.handleInput("idOrigineRisque", e)}
                >
                    {
                        originesRisque.map((t) => <MenuItem key={t._id} value={t._id}>{t.nom}</MenuItem>)
                    }
                </Select>
            </FormControl>;
        }

        if (typesRisque?.length > 0) {

            blockTypesRisque = <FormControl>
                <InputLabel>Type du risque</InputLabel>
                <Select
                    autoWidth={true}
                    value={this.state.idTypeRisque}
                    onChange={e => this.handleInput("idTypeRisque", e)}
                >
                    {
                        typesRisque.map((t) => <MenuItem key={t._id} value={t._id}>{t.nom}</MenuItem>)
                    }
                </Select>
            </FormControl>;
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Ajouter un risque
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Button onClick={() => this.props.history.push("/risques")}>
                                <i className="fa fa-dashboard"></i>
                                Risques
                            </Button>
                        </li>
                        <li className="active">
                            Ajouter un risque
                        </li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Informations</h3>
                                </div>
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            {blockCategoriesRisque}
                                        </div>
                                        <div className="form-group">
                                            {blockOriginesRisque}
                                        </div>
                                        <div className="form-group">
                                            <GoogleAddress
                                                defaultAddress={this.state.adresse}
                                                handleAddress={this.handleSelectAdresse} />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                label="Description"
                                                fullWidth={true}
                                                onChange={e => this.handleInput("description", e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            {blockTypesRisque}
                                        </div>
                                        
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        this.state.image
                                                            ?
                                                            <div>
                                                                <span>{this.state.image.name}</span>
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    size="large"
                                                                    color="secondary"
                                                                    onClick={this.deleteImage}
                                                                >
                                                                    Supprimer l'image
                                                                </Button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        color="primary"
                                                        accept="image/*"
                                                        type="file"
                                                        onChange={this._handleImageChange}
                                                        id="icon-button-file"
                                                        style={{ display: 'none', }}
                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            size="large"
                                                            color="primary"
                                                        >
                                                            Ajouter une image
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Enregistrer
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
