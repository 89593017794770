import React from 'react';
import Toast from '../../components/toast/Toast';
import './App.css';
import Menu from '../../components/menu/Menu';
import Login from '../../components/login/Login';
import Users from '../../components/users/Users';
import AddUser from '../../components/users/AddUser';
import UpdateUser from '../../components/users/UpdateUser';
import { getToken } from '../../tools/localStorage';
import { Switch, Route, HashRouter } from 'react-router-dom';
import Etablissements from '../../components/etablissement/Etablissements';
import AddEtablissement from '../../components/etablissement/AddEtablissement';
import UpdateEtablissement from '../../components/etablissement/UpdateEtablissement';
import Risques from '../../components/risques/Risques';
import AddRisque from '../../components/risques/AddRisque';
import UpdateRisque from '../../components/risques/UpdateRisque';
import TypeRisques from '../../components/typeRisque/TypeRisques';
import AddTypeRisque from '../../components/typeRisque/AddTypeRisque';
import UpdateTypeRisque from '../../components/typeRisque/UpdateTypeRisque';
import Settings from '../../components/settings/Settings';
import CategoriesRisque from '../../components/categorieRisque/CategoriesRisque';
import AddCategorieRisque from '../../components/categorieRisque/AddCategorieRisque';
import UpdateCategorieRisque from '../../components/categorieRisque/UpdateCategorieRisque';
import { getConnectedUser } from '../../actions/auth';
import { getSettings } from '../../actions/settings';
import TypeEtablissement from '../../components/typeEtablissement/TypeEtablissement';
import AddTypeEtablissement from '../../components/typeEtablissement/AddTypeEtablissement';
import UpdateTypeEtablissement from '../../components/typeEtablissement/UpdateTypeEtablissement';
import OrigineRisques from '../../components/origineRisque/OrigineRisques';
import AddOrigineRisque from '../../components/origineRisque/AddOrigineRisque';
import UpdateOrigineRisque from '../../components/origineRisque/UpdateOrigineRisque';
import MentionsLegales from '../../components/MentionsLegales';
import APropos from '../../components/APropos';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default class App extends React.Component {

  state = {
    token: null,
  };

  componentDidMount() {
    const that = this;
    that.setState({
      token: getToken()
    }, () => {
      if (that.state.token) {
        Promise.all([getSettings(), getConnectedUser()])
          .then(() => {
            that.props.history.push("users");
          });
      }
    });
  }

  render() {
    return (
      <>
        <Toast />
        <HashRouter
          history={this.props.history}
        >
          <Switch>

            <Route exact path="/mentions-legales">
              <MentionsLegales/>
            </Route>
            <Route exact path="/users">
              <Menu history={this.props.history} component={<Users history={this.props.history} />} />
            </Route>
            <Route exact path="/users/add">
              <Menu history={this.props.history} component={<AddUser history={this.props.history} />} />
            </Route>
            <Route exact path="/users/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateUser history={this.props.history} {...props} />} />} />

            <Route exact path="/etablissements">
              <Menu history={this.props.history} component={<Etablissements history={this.props.history} />} />
            </Route>
            <Route exact path="/etablissements/add">
              <Menu history={this.props.history} component={<AddEtablissement history={this.props.history} />} />
            </Route>
            <Route exact path="/etablissements/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateEtablissement history={this.props.history} {...props} />} />} />

            <Route exact path="/risques">
              <Menu history={this.props.history} component={<Risques history={this.props.history} />} />
            </Route>
            <Route exact path="/risques/add">
              <Menu history={this.props.history} component={<AddRisque history={this.props.history} />} />
            </Route>
            <Route exact path="/risques/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateRisque history={this.props.history} {...props} />} />} />

            <Route exact path="/typesRisque">
              <Menu history={this.props.history} component={<TypeRisques history={this.props.history} />} />
            </Route>
            <Route exact path="/typesRisque/add">
              <Menu history={this.props.history} component={<AddTypeRisque history={this.props.history} />} />
            </Route>
            <Route exact path="/typesRisque/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateTypeRisque history={this.props.history} {...props} />} />} />

            <Route exact path="/categories-risque">
              <Menu history={this.props.history} component={<CategoriesRisque history={this.props.history} />} />
            </Route>
            <Route exact path="/categories-risque/add">
              <Menu history={this.props.history} component={<AddCategorieRisque history={this.props.history} />} />
            </Route>
            <Route exact path="/categories-risque/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateCategorieRisque history={this.props.history} {...props} />} />} />

            <Route exact path="/types-etablissement">
              <Menu history={this.props.history} component={<TypeEtablissement history={this.props.history} />} />
            </Route>
            <Route exact path="/types-etablissement/add">
              <Menu history={this.props.history} component={<AddTypeEtablissement history={this.props.history} />} />
            </Route>
            <Route exact path="/types-etablissement/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateTypeEtablissement history={this.props.history} {...props} />} />} />

            <Route exact path="/originesRisque">
              <Menu history={this.props.history} component={<OrigineRisques history={this.props.history} />} />
            </Route>
            <Route exact path="/originesRisque/add">
              <Menu history={this.props.history} component={<AddOrigineRisque history={this.props.history} />} />
            </Route>
            <Route exact path="/originesRisque/:id/update" render={(props) => <Menu history={this.props.history} component={<UpdateOrigineRisque history={this.props.history} {...props} />} />} />

            <Route exact path="/a-propos">
              <Menu history={this.props.history} component={<APropos history={this.props.history} />} />
            </Route>

            <Route exact path="/settings">
              <Menu history={this.props.history} component={<Settings history={this.props.history} />} />
            </Route>

            <Route exact path="/">
              <Login history={this.props.history} />
            </Route>
          </Switch>
        </HashRouter>
      </>
    )
  }
}