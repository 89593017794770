import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export default class GoogleAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.defaultAddress || '' };
  }

  componentWillReceiveProps(newProps){
    this.setState({
        address: newProps.defaultAddress || ''
    });
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
          getLatLng(results[0])
          .then(latLng => {
            let locality = '', country = '', postal_code = '';

            if (results && results[0]?.address_components?.length > 0) {
              for (const ac of results[0].address_components) {
                  if (!ac.types || ac.types.length < 1) {
                      continue;
                  }
                  if (ac.types.includes("locality")) {
                      locality = ac.long_name;
                  } else if (ac.types.includes("country")) {
                      country = ac.long_name;
                  } else if (ac.types.includes("postal_code")) {
                      postal_code = ac.long_name;
                  }
              }
          }
  
          this.props.handleAddress(address, latLng, locality, country, postal_code);
          })
          .catch(error => console.error('Error', error));
      })
      .then(() => this.setState({ address }))
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Adresse ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}