import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { addTypeEtablissement } from '../../actions/typeEtablissement';

export default class AddTypeEtablissement extends React.Component {
    state = {
        nom: '',
    };

    handleInput = (identifiant, e) => {
        this.setState({
            [identifiant]: e.target.value,
        });
    };

    handleSubmit = () => {
        addTypeEtablissement(this.state.nom)
            .then(() => {
                sendMessageSuccess("Type d'établissement bien ajouté !");
                this.props.history.push("/types-etablissement");
            })
            .catch(error => sendMessageError(error));
    };

    render() {
        const { nom } = this.state;

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Ajouter un type d'établissement
                    </h1>
                    <ol className="breadcrumb">
                        <li>
                            <Button onClick={() => this.props.history.push("/types-etablissement")}>
                                <i className="fa fa-dashboard"></i>
                                Types d'établissement
                            </Button>
                        </li>
                        <li className="active">
                            Ajouter un type d'établissement
                        </li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Informations</h3>
                                </div>
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Nom"
                                                fullWidth={true}
                                                value={nom}
                                                onChange={e => this.handleInput("nom", e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Enregistrer
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
