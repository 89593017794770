import { get, removeApi, postMultipartApi } from '../tools/api';

export function loadCategoriesRisque() {
  return new Promise((resolve, reject) => {
    get("/categories-risque")
      .then((response) => {
        if (response && response.categories_risque) {
          return resolve(response.categories_risque);
        } else {
          return reject("Erreur lors de la récupération des catégorie de risque");
        }
      })
      .catch((response) => {
        return reject(response && response.error ? response.error : "Erreur lors de la récupération des catégorie de risque");
      });
  });
}

export function removeCategorieRisque(id) {
  return new Promise((resolve, reject) => {
    removeApi("/api/backend/categories-risque/" + id)
      .then(() => resolve())
      .catch(error => reject(error?.error));
  });
}

export function addCategorieRisque(
  nom, 
  image, 
  perimetre_alerte, 
  duree_affichage_alerte, 
  public_cible_alerte, 
  originesRisque, 
  imageUrl,
  activeQuePourParcelle) {
  return new Promise((resolve, reject) => {
    postMultipartApi("/api/backend/categories-risque", [{
      key: "categorieRisque",
      value: JSON.stringify(getData(nom, imageUrl, perimetre_alerte, duree_affichage_alerte, public_cible_alerte, originesRisque, activeQuePourParcelle)),
    }], image)
      .then(() => resolve())
      .catch(error => reject(error?.error));
  });
}

export function updateCategorieRisque(
  id, 
  nom, 
  image, 
  perimetre_alerte, 
  duree_affichage_alerte, 
  public_cible_alerte, 
  originesRisque, 
  imageUrl,
  activeQuePourParcelle) {
  return new Promise((resolve, reject) => {
    postMultipartApi("/api/backend/categories-risque/" + id, [{
      key: "categorieRisque",
      value: JSON.stringify(getData(nom, imageUrl, perimetre_alerte, duree_affichage_alerte, public_cible_alerte, originesRisque, activeQuePourParcelle)),
    }], image)
      .then(() => resolve())
      .catch(error => reject(error?.error));
  });
}

export function removeCategorieRisqueImage(id) {
  return new Promise((resolve, reject) => {
    removeApi("/api/backend/categories-risque/" + id + "/image")
      .then(() => resolve())
      .catch(error => reject(error?.error));
  })
}

function getData(nom, imageUrl, perimetre_alerte, duree_affichage_alerte, public_cible_alerte, originesRisque, activeQuePourParcelle) {
  const data = {
    nom,
    image: imageUrl,
    perimetre_alerte: parseFloat(perimetre_alerte),
    duree_affichage_alerte: parseInt(duree_affichage_alerte),
    public_cible_alerte,
    originesRisque,
    activeQuePourParcelle
  };
  if (public_cible_alerte === -1) {
    delete data.public_cible_alerte;
  }

  return data;
}