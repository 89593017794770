import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import { getSettings, updateSettings } from '../../actions/settings';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getConnectedUser } from '../../actions/auth';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class Settings extends React.Component {
    state = {
        settings: null,
        isAdminOrSuperAdmin: false,
    };

    componentDidMount() {
        getConnectedUser()
            .then(connectedUser => {
                this.setState({
                    isAdminOrSuperAdmin: connectedUser.role.nom_role === "Administrateur" || connectedUser.role.nom_role === "Super Administrateur",
                });
            })
            .catch(err => sendMessageError(err));

        getSettings()
            .then(settings => {
                this.setState({ settings });
            })
            .catch(error => sendMessageError(error));
    }

    handleInput = (identifiant, e, isFloat) => {
        const { settings } = this.state;
        settings[identifiant] = isFloat === true ? parseFloat(e.target.value) : e.target.value;
        this.setState({ settings });
    };

    handleSwitchTypeRisque = (e) => {
        const { settings } = this.state;
        this.setState({
            settings: {
                ...settings,
                showTypeRisque: e.target.checked,
            }
        });
    };

    handleSwitchUseParcelle = (e) => {
        const { settings } = this.state;
        this.setState({
            settings: {
                ...settings,
                useParcelle: e.target.checked,
            }
        });
    };

    handleSwitchNotifierDomicileParcelle = e => {
        const { settings } = this.state;
        this.setState({
            settings: {
                ...settings,
                notifierDomicileParcelle: e.target.checked,
            }
        });
    };

    handleCodeEntrepriseContributeur = e => {
        const { settings } = this.state;
        this.setState({
            settings: {
                ...settings,
                remplirCodeEntrepriseContributeur: e.target.checked,
            }
        });
    };

    handleSubmit = () => {
        updateSettings(this.state.settings)
            .then(() => sendMessageSuccess("Paramétrage bien modifié !"))
            .catch(error => sendMessageError(error));
    };

    render() {
        const { settings, isAdminOrSuperAdmin } = this.state;

        if (!settings) {
            return <div className="content-wrapper">
                <section className="content-header">
                    <CircularProgress />
                </section>
            </div>;
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Paramétrage
                    </h1>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box box-primary">
                                <form role="form">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Email contributeur"
                                                value={settings.email_contributeur}
                                                fullWidth={true}
                                                onChange={e => this.handleInput("email_contributeur", e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="box-body">
                                        <div className="form-group">
                                            <TextField
                                                label="Périmètre d'affichage des risques (en mètres)"
                                                value={settings.perimetreAffichageRisque}
                                                fullWidth={true}
                                                type="number"
                                                onChange={e => this.handleInput("perimetreAffichageRisque", e, true)}
                                            />
                                        </div>
                                    </div>

                                    {
                                        isAdminOrSuperAdmin ?
                                            <div className="box-body">
                                                <div className="form-group">
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={settings.showTypeRisque}
                                                            onChange={e => this.handleSwitchTypeRisque(e)}
                                                            color="primary" />}
                                                        label="Afficher dans le menu les types de risque"
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        isAdminOrSuperAdmin ?
                                            <div className="box-body">
                                                <div className="form-group">
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={settings.useParcelle}
                                                            onChange={e => this.handleSwitchUseParcelle(e)}
                                                            color="primary" />}
                                                        label="Activer la fonctionnalité des parcelles"
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        isAdminOrSuperAdmin ?
                                            <div className="box-body">
                                                <div className="form-group">
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={settings.notifierDomicileParcelle}
                                                            onChange={e => this.handleSwitchNotifierDomicileParcelle(e)}
                                                            color="primary" />}
                                                        label="Notifier les risques proches des domiciles et des parcelles"
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        isAdminOrSuperAdmin ?
                                            <div className="box-body">
                                                <div className="form-group">
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={settings.remplirCodeEntrepriseContributeur}
                                                            onChange={e => this.handleCodeEntrepriseContributeur(e)}
                                                            color="primary" />}
                                                        label="Contrôler le code entreprise avant la demande contributeur"
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            </div> : null
                                    }

                                    <div className="box-footer text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                        >
                                            Enregistrer
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
