import { get, removeApi, putApi, postApi } from '../tools/api';

export function loadTypeEtablissement() {
  return new Promise((resolve, reject) => {
    get("/types-etablissement")
      .then((response) => {
        if (response && response.types_etablissements) {
          return resolve(response.types_etablissements);
        } else {
          return reject("Erreur lors de la récupération des types d'établissement");
        }
      })
      .catch((response) => {
        if (response && response.error) {
          return reject(response.error);
        } else {
          return reject("Erreur lors de la récupération des types d'établissement");
        }
      });
  });
}

export function addTypeEtablissement(nom) {
  return new Promise((resolve, reject) => {
    postApi("/api/backend/types-etablissement", {
      nom
    })
      .then(() => resolve())
      .catch(err => reject(err));
  })
}

export function removeTypeEtablissement(id) {
  return new Promise((resolve, reject) => {
    removeApi("/api/backend/types-etablissement/" + id)
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateTypeEtablissement(id, nom) {
  return new Promise((resolve, reject) => {
    putApi("/api/backend/types-etablissement/" + id, { nom })
      .then(() => resolve())
      .catch(err => reject(err));
  })
}

export function getTypeEtablissement(id) {
  return new Promise((resolve, reject) => {
    get("/api/backend/types-etablissement/" + id)
      .then(typeEtablissement => resolve(typeEtablissement.type_etablissement))
      .catch(err => reject(err));
  })
}