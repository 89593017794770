import React from 'react';
import Button from '@material-ui/core/Button';
import { loadCategoriesRisque, removeCategorieRisque } from '../../actions/categoriesRisque';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import { TableContainer } from '@material-ui/core';
import { url } from '../../tools/api';

export default class categoriesRisque extends React.Component {
    state = {
        categoriesRisque: [],
    };

    componentDidMount() {
        this.__loadCategorieRisque();
    }

    __loadCategorieRisque = () => {
        loadCategoriesRisque()
            .then((categoriesRisque) => {
                this.setState({
                    categoriesRisque: categoriesRisque.sort((a, b) => {
                        if (a.nom < b.nom) { return -1; }
                        if (a.nom > b.nom) { return 1; }
                        return 0;
                    }),
                });
            });
    };

    handleRemove = (idCategorieRisque) => {
        removeCategorieRisque(idCategorieRisque)
            .then(() => {
                this.__loadCategorieRisque();
                sendMessageSuccess("Catégorie de risque bien supprimée")
            })
            .catch(error => sendMessageError(error));
    };

    render() {
        const { categoriesRisque } = this.state;
        let data = [];

        if (categoriesRisque?.length > 0) {
            for (const categorieRisque of categoriesRisque) {

                data.push(
                    <TableRow key={categorieRisque._id}>
                        <TableCell>
                            {
                                categorieRisque.image?.length > 0 ? <img
                                    style={{ maxWidth: 250 }}
                                    src={url + "/static/images/categorie-risque/" + categorieRisque._id}
                                    alt="Photo"
                                /> : null
                            }
                        </TableCell>
                        <TableCell>{categorieRisque.nom}</TableCell>
                        <TableCell>{categorieRisque.perimetre_alerte}</TableCell>
                        <TableCell>{categorieRisque.duree_affichage_alerte}</TableCell>
                        <TableCell>
                            <Button onClick={() => this.props.history.push("/categories-risque/" + categorieRisque._id + "/update")}>
                                Détail
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Button color="secondary" onClick={() => this.handleRemove(categorieRisque._id)}>
                                Supprimer
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            }
        }

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Liste des catégories de risque
                    </h1>
                </section>
                <section className="content">
                    <div className="row mb-20">
                        <div className="col-md-12">
                            <Button color="primary" onClick={() => this.props.history.push("/categories-risque/add")}>
                                Ajouter une catégorie de risque
                            </Button>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="row mt-20">
                        <div className="col-md-12">
                            <Paper>
                                <TableContainer>
                                    <Table selectable={"false"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Périmètre</TableCell>
                                                <TableCell>Durée d'affichage</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}