import { get, postApi, removeApi, putApi, downloadFileAsText, postMultipartApi } from '../tools/api';
import FileSaver from 'file-saver';

export const SET_OFFSET_ETABLISSEMENTS = 'SET_OFFSET_ETABLISSEMENTS';
export const SET_FILTRE_NOM_ETABLISSEMENTS = 'SET_FILTRE_NOM_ETABLISSEMENTS';

export function importCsv(file) {
    return new Promise((resolve, reject) => {
        postMultipartApi("/api/backend/etablissements/import", [], file)
            .then(() => {
                return resolve();
            })
            .catch((response) => {
                return reject();
            });
    });
}

export function exportCsv() {
    return new Promise((resolve, reject) => {
        downloadFileAsText("/api/backend/etablissements/export")
            .then((file, type) => {
                if (!file) {
                    return reject("Erreur lors du téléchargement du fichier");
                }

                const blob = new Blob([file], { type: type });
                FileSaver.saveAs(blob, "etablissements_csv.csv");
                return resolve();
            })
            .catch(() => {
                return reject("Erreur inconnue");
            });
    });
}

export function loadEtablissements(offset, filtre) {
    return new Promise((resolve, reject) => {
        if (filtre) {
            filtre = filtre.toLowerCase();
        }

        get('/api/backend/etablissements', {
            offset: offset,
            filtre: filtre,
        })
            .then((response) => {
                if (response && response.etablissements) {
                    return resolve(response.etablissements);
                }
                return reject("Erreur lors de la récupération des utilisateurs");
            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la récupération des utilisateurs");
            });
    });
}

export function addEtablissement(nom,
    adresse,
    telephone,
    lat,
    lon,
    idTypeEtablissement) {
    return new Promise((resolve, reject) => {
        if (!nom || nom.length === 0) {
            return reject("Vous devez saisir un nom");
        }

        if (!adresse || adresse.length === 0) {
            return reject("Vous devez saisir une adresse");
        }

        if (!telephone || telephone.length !== 10) {
            return reject("Le numéro de téléphone ne contient pas 10 caractères");
        }

        if (!lat || lat === 0.0) {
            return reject("Erreur lors de la récupération de la latitude de l'adresse");
        }

        if (!lon || lon === 0.0) {
            return reject("Erreur lors de la récupération de la longitude de l'adresse");
        }

        if (!idTypeEtablissement || idTypeEtablissement.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant du type d'établissement");
        }

        postApi("/api/backend/etablissements", {
            nom_etablissement: nom,
            adresse: adresse,
            telephone: telephone,
            location: {
                coordinates: [lon, lat],
            },
            type_etablissement: {
                id_type_etablissement: idTypeEtablissement,
            },
        })
            .then((response) => {
                if (response && response.etablissement) {
                    return resolve();
                }
                return reject("Erreur lors de la création de l'établissement");

            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la création de l'établissement");

            });
    });
}

export function updateEtablissement(idEtablissement,
    nom,
    adresse,
    telephone,
    lat,
    lon,
    idTypeEtablissement) {
    return new Promise((resolve, reject) => {
        if (!idEtablissement || idEtablissement.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'établissement");
        }

        if (!nom || nom.length === 0) {
            return reject("Vous devez saisir un nom");
        }

        if (!adresse || adresse.length === 0) {
            return reject("Vous devez saisir une adresse");
        }

        if (!telephone || telephone.length !== 10) {
            return reject("Le numéro de téléphone ne contient pas 10 caractères");
        }

        if (!lat || lat === 0.0) {
            return reject("Erreur lors de la récupération de la latitude de l'adresse");
        }

        if (!lon || lon === 0.0) {
            return reject("Erreur lors de la récupération de la longitude de l'adresse");
        }

        if (!idTypeEtablissement || idTypeEtablissement.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant du type d'établissement");
        }

        putApi("/api/backend/etablissements/" + idEtablissement, {
            nom_etablissement: nom,
            adresse: adresse,
            telephone: telephone,
            location: {
                coordinates: [lon, lat],
            },
            type_etablissement: {
                id_type_etablissement: idTypeEtablissement,
            },
        })
            .then((response) => {
                if (response && response.etablissement) {
                    return resolve();
                }
                return reject("Erreur lors de la modification de l'établissement");
            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la modification de l'établissement");
            });
    });
}

export function getEtablissement(idEtablissement) {
    return new Promise((resolve, reject) => {
        if (!idEtablissement || idEtablissement.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'établissement");
        }

        get("/api/backend/etablissements/" + idEtablissement)
            .then((response) => {
                if (response && response.etablissement) {
                    return resolve(response.etablissement);
                }
                return reject("Erreur lors de la récupération de l'établissement");
            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la récupération de l'établissement");
            });
    });
}

export function removeEtablissement(idEtablissement) {
    return new Promise((resolve, reject) => {
        if (!idEtablissement || idEtablissement.length === 0) {
            return reject("Erreur lors de la récupération de l'identifiant de l'etablissement");
        }

        removeApi("/api/backend/etablissements/" + idEtablissement)
            .then(() => {
                return resolve();
            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la suppression de l'etablissement");
            });
    });
}

export function countEtablissements() {
    return new Promise((resolve, reject) => {
        get("/api/backend/etablissements/count")
            .then((response) => {
                if (response && response.count !== undefined) {
                    return resolve(response.count);
                }
                return reject("Erreur lors de la récupération du nombre d'établissement");
            })
            .catch((response) => {
                return reject(response && response.error ? response.error : "Erreur lors de la récupération du nombre d'établissement");
            });
    });
}
