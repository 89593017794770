import { get, putApi } from '../tools/api';

export function getContentAPropos() {
    return new Promise((resolve, reject) => {
        get("/api/a-propos")
            .then(response => {
                if (response?.content?.length > 0) {
                    return resolve(response.content);
                }

                return resolve("");
            })
            .catch(err => reject(""));
    })
}

export function saveContentAPropos(content) {
    return new Promise((resolve, reject) => {
        putApi("/api/a-propos", {
            content
        })
            .then(response => {
                console.error(response);
                if (response?.content?.length > 0) {
                    return resolve(response.content);
                }

                return resolve("");
            })
            .catch(err => reject(""));
    })
}