import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getEtablissement, updateEtablissement } from '../../actions/etablissements';
import { loadTypeEtablissement } from '../../actions/typeEtablissement';
import { sendMessageError, sendMessageSuccess } from '../../actions/toast';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GoogleAddress from '../GoogleAddress';

export default class UpdateEtablissement extends React.Component {
  state = {
    idEtablissement: null,
    nom: '',
    adresse: '',
    telephone: '',
    lat: 0.0,
    lon: 0.0,
    typesEtablissement: [],
    idTypeEtablissement: '',
  };

  componentDidMount() {
    const that = this;

    const idEtablissement = this.props.match.params.id;
    if (!idEtablissement || idEtablissement.length === 0) {
      this.props.history.push("etablissements");
      return;
    }

    that.setState({
      idEtablissement
    }, () => {
      //On charge les types d'établissement
      loadTypeEtablissement()
        .then((typesEtablissement) => {
          that.setState({
            typesEtablissement,
          });
        })
        .catch((error) => sendMessageError(error));

      //On charge l'établissement à modifier
      getEtablissement(that.state.idEtablissement)
        .then((etablissement) => {
          if (etablissement && etablissement.location && etablissement.type_etablissement) {
            that.setState({
              nom: etablissement.nom_etablissement,
              adresse: etablissement.adresse,
              telephone: etablissement.telephone,
              lat: etablissement.location.coordinates[0],
              lon: etablissement.location.coordinates[1],
              idTypeEtablissement: etablissement.type_etablissement.id_type_etablissement,
            });
          } else {
            sendMessageError("Erreur lors de la récupération de l'établissement");
          }
        })
        .catch(error => sendMessageError(error));
    });
  }

  handleInput = (identifiant, e) => {
    this.setState({
      [identifiant]: e.target.value,
    });
  };

  handleChangeAdresse = (adresse) => {
    this.setState({
      adresse,
    });
  };

  handleSelectAdresse = (adresse, latLng) => {
    this.setState({
      adresse: adresse,
      lat: latLng.lat,
      lon: latLng.lng
    });
  };

  handleSubmit = () => {
    updateEtablissement(
      this.state.idEtablissement,
      this.state.nom,
      this.state.adresse,
      this.state.telephone,
      this.state.lat,
      this.state.lon,
      this.state.idTypeEtablissement,
    )
      .then(() => {
        sendMessageSuccess("Etablissement bien modifié !");
        this.props.history.push("/etablissements");
      })
      .catch(error => sendMessageError(error));
  };

  render() {
    const { typesEtablissement } = this.state;
    let blockTypesEtablissement = null;

    if (typesEtablissement && typesEtablissement.length > 0) {
      blockTypesEtablissement = <FormControl>
        <InputLabel>Type d'établissement</InputLabel>
        <Select
          autoWidth={true}
          value={this.state.idTypeEtablissement}
          onChange={e => this.handleInput("idTypeEtablissement", e)}
        >
          {
            typesEtablissement.map((t) => <MenuItem key={t._id} value={t._id}>
              {t.nom}
            </MenuItem>
            )
          }
        </Select>
      </FormControl>;
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Modifier un établissement
          </h1>
          <ol className="breadcrumb">
            <li>
              <Button onClick={() => this.props.history.push("/etablissements")}>
                <i className="fa fa-dashboard"></i>
                Etablissements
              </Button>
            </li>
            <li className="active">
              Modifier un établissement
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Informations</h3>
                </div>
                <form role="form">
                  <div className="box-body">
                    <div className="form-group">
                      {blockTypesEtablissement}
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("nom", e)}
                        value={this.state.nom}
                        label="Nom"
                      />
                    </div>
                    <div className="form-group">
                      <GoogleAddress  defaultAddress={this.state.adresse} handleAddress={this.handleSelectAdresse}/>
                    </div>
                    <div className="form-group">
                      <TextField
                        fullWidth={true}
                        onChange={e => this.handleInput("telephone", e)}
                        value={this.state.telephone}
                        label="Telephone"
                      />
                    </div>
                  </div>

                  <div className="box-footer text-center">
                    <Button
                      onClick={this.handleSubmit}
                      color="primary"
                    >
                      Enregistrer
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}